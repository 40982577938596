// App.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LanguageProvider } from './contexts/LanguageContext';
import PrivateRoute from './components/PrivateRoute';

// Import av sider
import Login from './pages/Login';
import MainMenu from './pages/MainMenu';
import Calendar from './pages/Calendar';
import MyProfile from './pages/MyProfile';
import DrivingRoutes from './pages/DrivingRoutes';
import Addresses from './pages/Addresses';
import Agreements from './pages/Agreements';
import Customers from './pages/Customers';
import SearchAddress from './pages/SearchAddress';
import NewInspection from './pages/NewInspection';
import MyCompleteTasks from './pages/MyCompleteTasks';
import IncompleteReports from './pages/IncompleteReports';
import FutureJobs from './pages/FutureJobs';
import NewWorkOrder from './pages/NewWorkOrder';
import ServiceAddress from './pages/ServiceAddress';
import CustomerOverview from './pages/CustomerOverview';
import CustomerNew from './pages/CustomerNew';
import AgreementNew from './pages/AgreementNew';
import ServiceAddressNew from './pages/ServiceAddressNew';
import AgreementNewTreat from './pages/AgreementNewTreat';
import AgreementNewProd from './pages/AgreementNewProd';
import AgreementNewPreview from './pages/AgreementNewPreview';
import CreateReport from './pages/legacy/CreateReport';
import VisitType from './pages/legacy/VisitType';
import CheckType from './pages/legacy/CheckType';
import PestActivity from './pages/legacy/PestActivity';
import WhatWeDone from './pages/legacy/WhatWeDone';
import Method from './pages/legacy/Method';
import Neighbors from './pages/legacy/Neighbors';
import Result from './pages/legacy/Result';
import Risk from './pages/legacy/Risk';
import Products from './pages/legacy/Products';
import Comment from './pages/legacy/Comment';
import Photos from './pages/legacy/Photos';
import Preview from './pages/legacy/Preview';
import EffortsSummary from './pages/legacy/EffortsSummary';
import InspectionDetails from './pages/InspectionDetails';
import MapView from './pages/MapView';

import './App.css';

const App = () => {
  return (
    <LanguageProvider>
      <Routes>
        {/* Offentlig rute */}
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />

        {/* Beskyttede ruter */}
        <Route
          path="/mainmenu"
          element={
            <PrivateRoute>
              <MainMenu />
            </PrivateRoute>
          }
        />
        <Route
          path="/calendar"
          element={
            <PrivateRoute>
              <Calendar />
            </PrivateRoute>
          }
        />
        <Route
          path="/myprofile"
          element={
            <PrivateRoute>
              <MyProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/driving-routes"
          element={
            <PrivateRoute>
              <DrivingRoutes />
            </PrivateRoute>
          }
        />
        <Route
          path="/addresses/:rute_omrade_id"
          element={
            <PrivateRoute>
              <Addresses />
            </PrivateRoute>
          }
        />
        <Route
          path="/agreements"
          element={
            <PrivateRoute>
              <Agreements />
            </PrivateRoute>
          }
        />
        <Route
          path="/customers"
          element={
            <PrivateRoute>
              <Customers />
            </PrivateRoute>
          }
        />
        <Route
          path="/search-address"
          element={
            <PrivateRoute>
              <SearchAddress />
            </PrivateRoute>
          }
        />
        <Route
          path="/new-inspection/:inspectionId?"
          element={
            <PrivateRoute>
              <NewInspection />
            </PrivateRoute>
          }
        />
        <Route
          path="/inspectiondetails/:inspectionId"
          element={
            <PrivateRoute>
              <InspectionDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-complete-tasks"
          element={
            <PrivateRoute>
              <MyCompleteTasks />
            </PrivateRoute>
          }
        />
        <Route
          path="/incomplete-reports"
          element={
            <PrivateRoute>
              <IncompleteReports />
            </PrivateRoute>
          }
        />
        <Route
          path="/future-jobs"
          element={
            <PrivateRoute>
              <FutureJobs />
            </PrivateRoute>
          }
        />
        <Route
          path="/new-work-order"
          element={
            <PrivateRoute>
              <NewWorkOrder />
            </PrivateRoute>
          }
        />
        <Route
          path="/serviceaddress/:behandlingsadrId"
          element={
            <PrivateRoute>
              <ServiceAddress />
            </PrivateRoute>
          }
        />
        <Route
          path="/customeroverview/:kundeId"
          element={
            <PrivateRoute>
              <CustomerOverview />
            </PrivateRoute>
          }
        />
        <Route
          path="/customernew"
          element={
            <PrivateRoute>
              <CustomerNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/agreement-new/:kundeId/:avtaleId?/:behandlingsadrId?"
          element={
            <PrivateRoute>
              <AgreementNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/serviceaddress-new/:kundeId/:avtaleId/:behandlingsadrId?"
          element={
            <PrivateRoute>
              <ServiceAddressNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/agreement-new-treat/:kundeId/:avtaleId/:behandlingsadrId"
          element={
            <PrivateRoute>
              <AgreementNewTreat />
            </PrivateRoute>
          }
        />
        <Route
          path="/agreement-new-prod/:kundeId/:avtaleId/:behandlingsadrId"
          element={
            <PrivateRoute>
              <AgreementNewProd />
            </PrivateRoute>
          }
        />
        <Route
          path="/agreement-new-preview/:kundeId/:avtaleId/:behandlingsadrId"
          element={
            <PrivateRoute>
              <AgreementNewPreview />
            </PrivateRoute>
          }
        />
        <Route
          path="/map-view"
          element={
            <PrivateRoute>
              <MapView />
            </PrivateRoute>
          }
        />
        {/* Legacy Pages */}
        {[
          { path: '/legacy/createreport/:behandlingsadrId/:oppdrag_id', element: <CreateReport /> },
          { path: '/legacy/visittype/:behandlingsadrId/:oppdrag_id', element: <VisitType /> },
          { path: '/legacy/checktype/:behandlingsadrId/:oppdrag_id', element: <CheckType /> },
          { path: '/legacy/pestactivity/:behandlingsadrId/:oppdrag_id', element: <PestActivity /> },
          { path: '/legacy/whatwedone/:behandlingsadrId/:oppdrag_id', element: <WhatWeDone /> },
          { path: '/legacy/method/:behandlingsadrId/:oppdrag_id', element: <Method /> },
          { path: '/legacy/neighbors/:behandlingsadrId/:oppdrag_id', element: <Neighbors /> },
          { path: '/legacy/result/:behandlingsadrId/:oppdrag_id', element: <Result /> },
          { path: '/legacy/risk/:behandlingsadrId/:oppdrag_id', element: <Risk /> },
          { path: '/legacy/products/:behandlingsadrId/:oppdrag_id', element: <Products /> },
          { path: '/legacy/comment/:behandlingsadrId/:oppdrag_id', element: <Comment /> },
          { path: '/legacy/photos/:behandlingsadrId/:oppdrag_id', element: <Photos /> },
          { path: '/legacy/preview/:behandlingsadrId/:oppdrag_id', element: <Preview /> },
          { path: '/legacy/effortssummary/:behandlingsadrId/:oppdrag_id', element: <EffortsSummary /> },
        ].map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={<PrivateRoute>{element}</PrivateRoute>}
          />
        ))}
      </Routes>
    </LanguageProvider>
  );
};

export default App;
