import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css'; // Import global styles
import './global.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ToastProvider } from './contexts/ToastContext';
import { FaExternalLinkAlt } from 'react-icons/fa'; // Import correct Font Awesome icon
import { loadConfig } from './loadConfig'; // Import loadConfig


const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

// Wrapper function to start the app after loading configuration
async function startApp() {
  try {
    await loadConfig(); // Load config.json before rendering the app
    root.render(
      <React.StrictMode>
        <ToastProvider>
          <Router>
            <App />
            <AddToHomeScreenPrompt />
          </Router>
        </ToastProvider>
      </React.StrictMode>
    );
  } catch (error) {
    console.error('Failed to load config.json:', error);
    // Render an error message if configuration loading fails
    root.render(
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <h1>Feil ved lasting av konfigurasjon</h1>
        <p>Vennligst kontakt administrator.</p>
      </div>
    );
  }
}

startApp(); // Call the startApp function

// Register the service worker with an update notification
serviceWorkerRegistration.register({
  onUpdate: registration => {
    if (window.confirm('A new version is available. Reload to update?')) {
      if (registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        registration.waiting.addEventListener('statechange', event => {
          if (event.target.state === 'activated') {
            window.location.reload(); // Reload the app after activating the new service worker
          }
        });
      }
    }
  }
});

reportWebVitals();

function AddToHomeScreenPrompt() {
  const [isIos, setIsIos] = useState(false);
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIos(/iphone|ipad|ipod/.test(userAgent));
    setIsInStandaloneMode(window.matchMedia('(display-mode: standalone)').matches);
  }, []);

  if (!isIos || isInStandaloneMode) {
    return null;
  }

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      width: '100%',
      background: '#f8f9fa',
      padding: '10px 0',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
      zIndex: 9999,
      textAlign: 'center',
    }}>
      <div style={{
        margin: '0 auto',
        paddingLeft: '20px',
        paddingRight: '30px',
        maxWidth: '90%',
      }}>
        <p style={{ margin: 0 }}>
          To add this app to your home screen, tap{' '}
          <FaExternalLinkAlt style={{ verticalAlign: 'middle', color: '#007AFF', width: '20px', height: '20px' }} />
          {' '}and select "Add to Home Screen".
        </p>
      </div>
    </div>
  );
}
