// Definer apiUrl som en variabel for å sikre at den kan oppdateres dynamisk
let apiUrl = '';

const config = {
  get apiUrl() {
    return apiUrl;
  },
  setApiUrl(url) {
    apiUrl = url;
  },
  googleMapsApiKey: 'AIzaSyDrHzOY8QzpscO3RehylhofGcQiGoarMqA',
  googleMapsLibraries: ['places', 'geometry', 'marker'],
  googleMapsMapId: '4377fffee11226d',
};

export default config;
