// src/loadConfig.js
// Brukes for å koble informasjon fra config.json i rot til config.js 
import config from './config';

export async function loadConfig() {
  try {
    console.log('Starting to load config.json...');
    const response = await fetch('/config.json'); // Henter config.json fra serveren

    if (!response.ok) {
      console.error('Failed to fetch config.json:', response.status, response.statusText);
      throw new Error(`Failed to load config.json: ${response.statusText}`);
    }

    const configData = await response.json();
    console.log('Config data fetched:', configData);

    if (configData.apiUrl) {
      config.setApiUrl(configData.apiUrl); // Setter apiUrl i config
      console.log(`Config loaded: apiUrl = ${configData.apiUrl}`);
    } else {
      console.error('apiUrl missing in config.json');
      throw new Error('Missing apiUrl in config.json');
    }
  } catch (error) {
    console.error('Error loading configuration:', error);
    throw error; // Kaster feil for å stoppe appen hvis config ikke lastes
  }
}
