import React, { useState, useContext, useEffect, useRef } from 'react';
import MainLayout from '../components/MainLayout';
import { LanguageContext } from '../contexts/LanguageContext';
import { FaCalendarAlt } from 'react-icons/fa';
import { BiHourglass } from 'react-icons/bi';
import { FaSyncAlt } from 'react-icons/fa';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import apiClient from '../api/apiClient';
import EventModal from '../components/EventModal'; // Importér EventModal
import './Calendar.css';
import config from '../config';

const Calendar = () => {
  const { translations } = useContext(LanguageContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dates, setDates] = useState([]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState('');
  const [events, setEvents] = useState({});
  const eventsListRef = useRef(null);
  const userId = localStorage.getItem('userid');
  const token = localStorage.getItem('token');
  const [loadedMonths, setLoadedMonths] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null); // Holder eventet som er valgt

 // Funksjon for å håndtere klikk på en event
 const handleEventClick = (event) => {
  setSelectedEvent(event); // Åpner modalen med valgt event
};

// Funksjon for å lukke modalen
const handleModalClose = () => {
  setSelectedEvent(null); // Fjerner valgt event og lukker modalen
};

  // Setter initiale datoer
  useEffect(() => {
    initializeDates(selectedDate);
    loadInitialEvents();
  }, [selectedDate]);

  // Funksjon for å initiere datoer en uke før og en uke etter valgt dato
  const initializeDates = (date) => {
    const datesArray = [];
    for (let i = -7; i <= 7; i++) {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() + i);
      datesArray.push(newDate);
    }
    setDates(datesArray);
  };

  // Laster eventer for inneværende og neste måned
  const loadInitialEvents = async () => {
    await loadEventsForMonth(selectedDate);
  };

  // Funksjon for å laste eventer for en gitt måned
  const loadEventsForMonth = async (date) => {
    const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;
    if (loadedMonths[monthYear]) return; // Hvis vi allerede har lastet denne måneden, hopp over

    try {
      const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
      const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      const startDate = monthStart.toISOString().split('T')[0];
      const endDate = monthEnd.toISOString().split('T')[0];

      const response = await apiClient.get(`${config.apiUrl}/getCalendarEventsByDateRange/${userId}/${startDate}/${endDate}?token=${token}`);
      if (response.data && response.data.status === 'success') {
        const eventsData = response.data.data.reduce((acc, event) => {
          const eventDate = event.startdate;
          if (!acc[eventDate]) {
            acc[eventDate] = [];
          }
          acc[eventDate].push(event);
          return acc;
        }, {});

        setEvents((prevEvents) => ({
          ...prevEvents,
          ...eventsData,
        }));

        setLoadedMonths((prevLoadedMonths) => ({
          ...prevLoadedMonths,
          [monthYear]: true,
        }));
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  // Funksjon for å håndtere endring av dato fra datovelgeren
  const handleDateChange = (date) => {
    setSelectedDate(date);
    initializeDates(date);
  };

  // Funksjon for å håndtere uendelig scrolling
  const loadMoreDates = (direction) => {
    const newDates = [...dates];
    if (direction === 'past') {
      const firstDate = newDates[0];
      for (let i = 1; i <= 7; i++) {
        const newDate = new Date(firstDate);
        newDate.setDate(newDate.getDate() - i);
        newDates.unshift(newDate);
      }
    } else if (direction === 'future') {
      const lastDate = newDates[newDates.length - 1];
      for (let i = 1; i <= 7; i++) {
        const newDate = new Date(lastDate);
        newDate.setDate(newDate.getDate() + i);
        newDates.push(newDate);
      }
    }
    setDates(newDates);
  };
  // Funksjon for å velge ikon basert på status
const getStatusIcon = (status) => {
  switch (status) {
    case 'Pending':
      return <BiHourglass className="status-icon" />;
    case 'In progress':
      return <FaSyncAlt className="status-icon" />;
    case 'Completed':
      return <AiOutlineCheckCircle className="status-icon" />;
    default:
      return null;
  }
};

  // Observer for å oppdatere gjeldende måned basert på hvilken dato som er synlig øverst
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const visibleEntry = entries.find((entry) => entry.isIntersecting);
        if (visibleEntry) {
          const visibleDate = new Date(visibleEntry.target.dataset.date);
          const newMonth = visibleDate.toLocaleString('en-GB', { month: 'long', year: 'numeric' });
          setCurrentMonth(newMonth);
          loadEventsForMonth(visibleDate); // Laster eventer for den synlige måneden
        }
      },
      {
        root: eventsListRef.current,
        threshold: 0.1,
      }
    );

    const items = eventsListRef.current?.querySelectorAll('.event-item');
    items?.forEach((item) => observer.observe(item));

    return () => {
      items?.forEach((item) => observer.unobserve(item));
    };
  }, [dates]);

  return (
    <MainLayout title={translations['calendar'] || "Calendar"}>
      <div className="calendar-container">
        <div className="calendar-header">
          <h1 className="calendar-title">{currentMonth}</h1>
          <button
            className="calendar-button"
            onClick={() => setIsCalendarOpen(!isCalendarOpen)}
          >
            <FaCalendarAlt />
          </button>
        </div>
        {isCalendarOpen && (
          <div className="datepicker-container">
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              inline
            />
          </div>
        )}
        <div
          className="events-list"
          ref={eventsListRef}
          onScroll={(e) => {
            const { scrollTop, scrollHeight, clientHeight } = e.target;
            if (scrollTop === 0) {
              loadMoreDates('past');
            } else if (scrollTop + clientHeight >= scrollHeight) {
              loadMoreDates('future');
            }
          }}
        >
          {dates.map((date, index) => {
            const dateString = date.toISOString().split('T')[0];
            return (
              <div key={index} className="event-item" data-date={date}>
                <div className="event-date">
                  <span className="event-day-number">{date.getDate()}</span>
                  <span className="event-day">{date.toLocaleDateString('en-GB', { weekday: 'short' })}</span>
                </div>
                <div className="event-details">
                  {events[dateString] && events[dateString].length > 0 ? (
                    events[dateString].map((event, idx) => (
                      <div
                        key={idx}
                        className="event-box"
                        style={{ backgroundColor: event.event_color }}
                        onClick={() => handleEventClick(event)}
                      >
                        <p><strong>{event.customer}</strong></p>
                        <p>{event.type_besok}</p>
                        <p>{event.address}, {event.city}</p>
                        <div className="status-icon-container">
                          {getStatusIcon(event.status)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>{translations['noEvents'] || 'No events available.'}</p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {selectedEvent && (
        <EventModal
        event={selectedEvent}
        onClose={handleModalClose}
        behandlingsadrId={selectedEvent?.behandlingsadr_id} // Send behandlingsadr_id
        oppdragId={selectedEvent?.oppdrag_id}             // Send oppdrag_id
        inspectionId={selectedEvent?.inspection_id}       // Send inspection_id
        progress={selectedEvent?.progress} // Send progress-verdien til modalen
      />
      )}
    </MainLayout>
  );
};

export default Calendar;
