import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './EventModal.css';
import apiClient from '../api/apiClient';
import { useToast } from '../contexts/ToastContext';
import { LanguageContext } from '../contexts/LanguageContext';
import { FaRoute } from 'react-icons/fa'; // Importer FaRoute-ikonet
import config from '../config';

const EventModal = ({ event, onClose, progress }) => {
  const { translations } = useContext(LanguageContext);
  const [startDate, setStartDate] = useState(event.startdate);
  const [userId, setUserId] = useState(event.user_id);
  const [technicians, setTechnicians] = useState([]);
  const navigate = useNavigate();
  const notify = useToast();

  // Hent teknikerlisten ved første innlasting
  useEffect(() => {
    const fetchTechnicians = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await apiClient.get(`${config.apiUrl}/getTechnicians?token=${token}`);
        if (response.data && response.data.status === 'success') {
          setTechnicians(response.data.data);
        } else {
          notify('error', translations.fetchTechniciansError || 'Failed to fetch technicians.');
        }
      } catch (error) {
        console.error('Error fetching technicians:', error);
        notify('error', translations.fetchTechniciansError || 'Failed to fetch technicians.');
      }
    };

    fetchTechnicians();
  }, [notify, translations]);

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      const payload = {
        startdate: startDate,
        user_id: parseInt(userId, 10),
        ...(event.inspection_id && { inspection_id: event.inspection_id }),
        ...(event.oppdrag_id && { oppdrag_id: event.oppdrag_id }),
      };

      if (event.inspection_id) {
        const response = await apiClient.put(`${config.apiUrl}/updCalendarInspectionEvent?token=${token}`, payload);
        if (response.data.status === 'success') {
          notify('success', translations.changesSavedInspection || 'Changes saved for inspection!');
        }
      } else if (event.oppdrag_id) {
        const response = await apiClient.put(`${config.apiUrl}/updCalendarOppdragEvent?token=${token}`, payload);
        if (response.data.status === 'success') {
          notify('success', translations.changesSavedOppdrag || 'Changes saved for oppdrag!');
        }
      }

      if (typeof window.refreshCalendar === 'function') {
        window.refreshCalendar();
      } else {
        window.location.reload();
      }

      onClose();
    } catch (error) {
      console.error('Error saving changes:', error);
      notify('error', translations.saveChangesError || 'Failed to save changes. Please try again.');
    }
  };

  const getResumeButtonText = () => {
    if (event.status === 'Completed') return translations.completed || "Completed";
    if (event.inspection_id) return translations.inspectionDetails || "Inspection Details";
    if (progress) return translations.resumeReport || "Resume Report";
    return translations.startReport || "Start Report";
  };

  const handleResumeClick = () => {
    if (event.inspection_id) {
      navigate(`/inspectiondetails/${event.inspection_id}`);
    } else if (event.oppdrag_id && event.behandlingsadr_id) {
      const url = progress || `/legacy/CreateReport/${event.behandlingsadr_id}/${event.oppdrag_id}`;
      navigate(url);
    }
  };

  const handleDirectionClick = () => {
    const mapsQuery = `${event.address}, ${event.city}`.replace(/ /g, '+'); // Bytt mellomrom med pluss
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${mapsQuery}`;
    window.open(googleMapsUrl, '_blank'); // Åpner Google Maps i ny fane
  };

  const isResumeDisabled = event.status === 'Completed';

  return (
    <div className="event-modal-overlay" onClick={(e) => e.target.className === 'event-modal-overlay' && onClose()}>
      <div className="event-modal-content">
        <h2>{event.type_besok}</h2>
        <div className="event-modal-info">
          <p>{event.customer}</p>
          <p>{event.address}, {event.zip} {event.city}</p>
          {event.oppdrag_id && <p><strong>{translations.oppdragId || "Oppdrag ID:"}</strong> {event.oppdrag_id}</p>}
          {event.inspection_id && <p><strong>{translations.inspectionId || "Inspection ID:"}</strong> {event.inspection_id}</p>}
        </div>
        <div className="event-modal-editable">
          <div className="event-description">
            {event.oppdrag_id 
              ? <strong>{translations.noteServiceAddress || "Note service address:"}</strong> 
              : <strong>{translations.titleInspection || "Title inspection:"}</strong>} 
            {event.event_descr || translations.noDescriptionAvailable || 'No description available'}
          </div>
          <label>
            {translations.startDate || "Start Date:"}
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              disabled={event.status === 'Completed'}
              className={event.status === 'Completed' ? 'input-disabled' : ''}
            />
          </label>
          <label>
            {translations.technician || "Technician:"}
            <select
              value={userId}
              onChange={(e) => setUserId(parseInt(e.target.value, 10))}
              disabled={event.status === 'Completed'}
              className={event.status === 'Completed' ? 'input-disabled' : ''}
            >
              {technicians.map((technician) => (
                <option key={technician.id} value={technician.id}>
                  {technician.fname} {technician.lname}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="event-modal-buttons">
          <button
            onClick={handleResumeClick}
            disabled={isResumeDisabled}
            className={isResumeDisabled ? 'button-disabled' : ''}
          >
            {getResumeButtonText()}
          </button>
          <button
            onClick={() => {
              if (event.behandlingsadr_id) {
                navigate(`/serviceaddress/${event.behandlingsadr_id}`);
              }
            }}
            disabled={!event.behandlingsadr_id}
            className={!event.behandlingsadr_id ? 'button-disabled' : ''}
          >
            {translations.showServiceCard || "Show Service Card"}
          </button>
          <button
            onClick={handleSave}
            disabled={event.status === 'Completed'}
            className={event.status === 'Completed' ? 'button-disabled' : ''}
          >
            {translations.save || "Save"}
          </button>
          <button onClick={handleDirectionClick}>
            <FaRoute /> {/* Bruker kun ikonet FaRoute */}
          </button>
        </div>
        <button className="event-modal-close" onClick={onClose}>
          {translations.close || "Close"}
        </button>
      </div>
    </div>
  );
};

export default EventModal;
